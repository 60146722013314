<template>
  <div class="editable-cell" @click.stop>
    <div v-if="editable" class="editable-cell-input-wrapper">
      <a-input
        :value="value"
        @change="handleChange"
        @pressEnter="check"
        @click.stop="() => $emit('click', editable)"
      />
      <a-icon
        type="check"
        class="editable-cell-icon-check"
        @click.stop.prevent="check"
      />
    </div>
    <div v-else class="editable-cell-text-wrapper">
      <div class="text">{{ value || " " }}</div>
      <a-icon
        type="edit"
        class="editable-cell-icon"
        @click.stop.prevent="edit"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    text: String,
  },
  data() {
    return {
      value: this.text,
      editable: false,
    };
  },
  methods: {
    handleChange(e) {
      const value = e.target.value;
      this.value = value;
    },
    check() {
      if (!this.value) {
        return;
      }
      this.editable = false;
      this.$emit("change", this.value);
    },
    edit() {
      this.editable = true;
    },
  },
};
</script>
<style lang="scss">
.editable-cell-text-wrapper {
  display: flex;
  align-items: center;
  .text {
    // width: 92%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.editable-cell-input-wrapper {
  display: flex;
  width: 100px;
  align-items: center;
  .editable-cell-icon-check {
    margin-left: 8px;
  }
}
</style>
