<template>
  <div class="customer">
    <div class="breadcrumb" v-if="!detailVisible">{{ $t("recommend") }}</div>
    <div class="recommends">
      <tool-bar
        v-if="!detailVisible"
        :menus="['tableView']"
        @search="handleSearch"
        @change-view="handleChangeView"
        :current="currentView"
      >
        <template v-slot:other>
          <n-button
            @click="handleCopy"
            icon="icon-fuzhi"
            :disable="disableCopy"
          >
            {{ $t("copy") }}
          </n-button>
        </template>
      </tool-bar>
      <div class="recommend-view">
        <!-- <a-empty v-if="empty" /> -->
        <n-table
          v-if="tableView"
          :data="recommends"
          :columns="columns"
          :selectedRow="ids"
          :pagination="pagination"
          :loading="loading"
          @change="handleTableChange"
          @select="handleSelectRow"
          @click="handleToggleView"
          :disabled="disable"
        >
        </n-table>
        <div
          class="detail-view"
          v-if="detailView || createView"
          v-loading="loading"
        >
          <detail
            v-if="detailVisible"
            :rid="rid"
            :visible.sync="detailVisible"
            :edit="edit"
            @change="handleChange"
            @ok="handleOk"
            @close="handleClose"
          ></detail>
        </div>
        <customer-dialog
          @select="handleCustomerSelect"
          :top="top"
          :left="left"
          :visible.sync="customerDialogVisible"
          url="/crm/recommend?menu=recommend&cw=tableView"
        ></customer-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import ToolBar from "../tool-bar/index.vue";
import NTable from "../table/index.vue";
import EditableCell from "../table/cell.vue";
import Detail from "./detail";
import CustomerDialog from "./dialog/CustomerDialog.vue";
import NButton from "../tool-bar/button/index.vue";
import api from "@/api";
import { createNamespacedHelpers } from "vuex";
const { mapState } = createNamespacedHelpers("jiyang/user");
const { mapMutations } = createNamespacedHelpers("jiyang/app");
const { mapMutations: mapMutationsRecommend } =
  createNamespacedHelpers("jiyang/recommend");
export default {
  data() {
    return {
      disable: false,
      originData: [],
      search: "",
      loading: false,
      detailVisible: false,
      customerDialogVisible: false,
      daterange: "",
      filterStates: [
        { classname: "", label: "未推荐" },
        { classname: "success", label: "已推荐" },
        { classname: "danger", label: "全部" },
      ],
      top: "20px",
      left: "500px",
      edit: true,
      update: false,
      currentView: "tableView",
      recommends: [],
      ids: [],
      url: "",
      record: {},
      rid: 0,
      user: {
        role: "admin",
        info: {},
      },
      pagination: {
        pageSize: 9,
        total: 0,
        page: 1,
        current: 1,
      },
      searchParam: {
        search_name: null,
        create_user_id: null,
        service_id: null,
        customer_id: null,
        status: 2,
        create_time: [],
      },
      customers: [],
      columns: [
        {
          title: this.$t("sn"),
          label: this.$t("sn"),
          dataIndex: "sn",
          key: "sn",
          width: "140px",
        },
        {
          label: this.$t("subject"),
          title: this.$t("subject"),
          dataIndex: "theme",
          width: "200px",
          key: "theme",
          customRender: (text, row) => {
            return row.is_owner ? (
              <editable-cell
                text={text}
                onClick={(val) => this.onEditing(val)}
                onChange={(val) => this.handleThemeChange(row, "theme", val)}
              />
            ) : (
              <span>{{ text }}</span>
            );
          },
        },
        {
          label: this.$t("customerName"),
          dataIndex: "customer_name",
          width: "140px",
          key: "customer_name",
          slots: {
            title: "customerName",
            type: "search",
            ok: (e) => {
              this.searchParam.customer_id = e.id;
            },
            api: api.getCustomerList,
          },
        },
        {
          label: this.$t("contact"),
          title: this.$t("contact"),
          width: "140px",
        },
        {
          label: this.$t("createTime"),
          dataIndex: "create_time",
          slots: {
            title: "createTime",
            type: "date",
            ok: (data) => {
              this.searchParam.create_time = data.timeString;
            },
          },
          width: "140px",
        },
        {
          label: this.$t("creator"),
          key: "create_user_name",
          dataIndex: "create_user_name",
          width: "140px",
          slots: {
            title: "creator",
            type: "search",
            ok: (e) => {
              this.searchParam.create_user_id = e.id;
            },
            api: api.getServiceList,
          },
        },
        {
          label: this.$t("state"),
          slots: {
            title: "status",
            type: "state",
            ok: (data) => {
              this.searchParam.status = data;
              this.getRecommendList();
            },
            list: [
              { classname: "", label: "未推荐" },
              { classname: "success", label: "已推荐" },
              { classname: "danger", label: "全部" },
            ],
          },
          dataIndex: "status",
          key: "status",
          width: "140px",
          customRender: (text, row) => {
            return (
              <a-tag
                color={text ? "#63d8bb" : "#c6e4fe"}
                onClick={(e) => this.handleSend(row, e)}
              >
                {text == 1 ? this.$t("recommended") : this.$t("unrecommend")}
              </a-tag>
            );
          },
        },
        {
          label: "操作",
          title: "操作",
          width: "100px",
          customRender: (text, row) => {
            return (
              <div onClick={this.noop}>
                <i
                  class="el-icon-document-copy"
                  style="margin-right:20px;font-size:20px"
                  onClick={(e) => this.handleCopy(e, row)}
                />
                <i
                  class="el-icon-delete"
                  style="font-size:20px"
                  onClick={(e) => this.handleDelete(e, row)}
                />
              </div>
            );
          },
        },
      ],
    };
  },
  watch: {
    $route: {
      handler(val) {
        this.init(val);
      },
      deep: true,
    },
    searchParam: {
      deep: true,
      handler() {
        this.getRecommendList();
      },
    },
  },

  computed: {
    ...mapState(["info"]),
    disableCopy() {
      return this.ids.length == 0;
    },
    empty() {
      return this.recommends.length == 0 && this.currentView == "cardView";
    },
    createView() {
      return this.currentView === "createView";
    },
    cardView() {
      return this.currentView === "cardView";
    },
    tableView() {
      return this.currentView === "tableView";
    },
    detailView() {
      return this.currentView === "detailView";
    },
  },
  created() {
    this.getCookie();
    this.init();
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", this.cancel, false);
    }
  },
  methods: {
    ...mapMutations(["setCollapse"]),
    ...mapMutationsRecommend(["clearRecommend"]),
    // async getCreator() {
    //   const res = await api.getServiceList();
    //   console.log(res);
    //   return
    // },
    onEditing(val) {
      this.disable = val;
    },
    noop(e) {
      if (e) {
        e.stopPropagation();
      }
    },
    cancel() {
      if (this.orderId) {
        this.$router.push({
          name: "/crm/recommend",
          params: { menu: "recommend" },
        });
      } else {
        this.$router.go(-1);
      }
    },
    async handleDelete(e, row) {
      if (e) {
        e.stopPropagation();
      }
      await api.deleteRecommendForm(row.id);
      this.refreshList();
    },
    handleInputCreateTime(val) {
      this.showCreateTimeFilter = false;
    },
    handleShowThemeFilter() {
      this.showThemeFilter = true;
    },
    handleRangePickerChange(date) {
      console.log(date);
      this.openRagePicker = false;
    },
    filterState(state) {
      if (state == 2) {
        this.dataSource = this.data;
        return;
      }
      this.dataSource = this.data.filter((d) => d.status == state);
    },
    modifyStyle() {
      document.getElementById("ant-table-column-title");
    },
    async handleThemeChange(row, dataIndex, theme) {
      this.disable = false;
      if (!theme) {
        return;
      }
      const recommends = [...this.recommends];
      const target = recommends.find((item) => item.id === row.id);
      if (target) {
        target[dataIndex] = theme;
        this.recommends = recommends;
        try {
          await api.updateRecommendTheme({ id: row.id, theme });
        } catch (e) {}
        await this.getRecommendList();
      }
    },
    getCookie() {
      let info = this.$cookie.get("user_info");
      this.user.info = JSON.parse(info);
    },
    handleOk(v) {
      this.detailVisible = false;
      this.currentView = "tableView";
      this.getRecommendList();
    },
    handleClose() {
      this.detailVisible = false;
      this.currentView = "tableView";
      this.getRecommendList();
    },
    handleSearch(input) {
      this.searchParam.search_name = input;
      this.getRecommendList();
    },
    async handleSend(row, e) {
      e.stopPropagation();
      if (row.status) {
        return;
      }
      if (!row.is_owner) {
        return;
      }
      this.record = row;
      if (!row.customer_id) {
        const target = e.target;
        const { x, y } = target.getBoundingClientRect();
        this.top = `${y + 10}px`;
        this.left = `${x - 100}px`;
        this.customerDialogVisible = true;
      } else {
        await api.sendRecommendForm({
          id: row.id,
          customer_id: row.customer_id,
        });
      }
      this.refreshList();
    },
    refreshList() {
      this.loading = true;
      this.getRecommendList();
      this.loading = false;
    },
    handleSelectRow(row) {
      this.ids = row;
    },
    handleCardEdit(customer) {
      this.currentView = "detailView";
      this.edit = true;
      this.record = customer;
    },
    handleEdit() {
      this.update = true;
      this.edit = true;
    },
    handleChange(customer) {
      this.record = customer;
    },
    init() {
      let type = this.user.info.user_type;
      let roleMap = {
        1: "admin",
        2: "seller",
        3: "seller",
      };
      this.currentView = "tableView";
      this.user.role = roleMap[type];
      this.cleanPageInfo();
      this.cleanRecord();
      this.switchApi();
    },
    async useRequest(api, data) {
      this.loading = true;
      await api(data);
      this.loading = false;
    },
    async handleCopy(e, row) {
      if (e) {
        e.stopPropagation();
      }
      if (row) {
        const id = row.id;
        this.ids.push(id);
      }
      const size = this.ids.length;
      for (let i = 0; i < size; i++) {
        await api.copyRecommendForm({ id: this.ids[i] });
      }
      this.getRecommendList();
      this.ids = [];
    },
    async getRecommendList() {
      this.loading = true;
      const { data, total, current_page, per_page } =
        await api.getRecommendList({
          ...this.pagination,
          ...this.searchParam,
        });
      this.recommends = data;
      this.originData = data;
      this.pagination = {
        pageSize: per_page,
        total: total,
        page: current_page,
        current: current_page,
      };
      this.loading = false;
    },
    switchApi() {
      this.ids = [];
      const { menu, url, v = "tableView" } = this.$route.query;
      this.detailVisible = false;
      this.currentView = v;
      if (v == "createView") {
        this.detailVisible = true;
      }
      this.url = url;
      switch (menu) {
        case "recommend":
          this.getRecommendList();
          break;
        default:
          this.getRecommendList();
      }
    },
    cleanPageInfo() {
      this.pagination = {
        pageSize: 9,
        total: 0,
        page: 1,
        current: 1,
      };
    },
    cleanRecord() {
      this.record = {};
    },
    handleCardPageChange(page, pageSize) {
      this.pagination = { pageSize, page };
      this.switchApi();
    },
    handleTableChange({ pagination }) {
      this.pagination = pagination;
      this.switchApi();
    },
    handleToggleView(record) {
      this.record = record;
      this.rid = record.id;
      this.setCollapse(true);
      this.currentView = "detailView";
      this.detailVisible = true;
    },

    handleChangeView(view) {
      this.currentView = view;
      if (view == "createView") {
        this.edit = true;
        this.detailVisible = true;
        this.cleanRecord();
      }
    },
    async handleCustomerSelect(c) {
      await api.sendRecommendForm({
        id: this.record.id,
        customer_id: c.id,
      });
      this.refreshList();
    },
  },
  destroyed() {
    window.removeEventListener("popstate", this.cancel, false);
  },
  components: {
    ToolBar,
    NTable,
    NButton,
    Detail,
    CustomerDialog,
    EditableCell,
  },
};
</script>

<style lang="scss">
.recommends {
  overflow: hidden;
  flex: 1;
  .crm-tool {
    padding-top: 15px;
  }
  .ant-tag {
    width: 100px;
    height: 26px;
    line-height: 26px;
    border-radius: 18px;
    text-align: center;
    color: #000;
  }
  .recommend-view {
    flex: 1;
    height: calc(100vh - 52px);
    overflow: hidden;
  }
  .customer-dialog {
    top: 271px;
    left: 530px;
  }
  .theme-filter {
    width: 246px;
    background: #fff;
  }
  .detail-view {
    overflow: hidden;
    display: flex;
    flex: 1;
    .recommend {
      flex: 1;
    }
  }
}
</style>
